import { get } from 'lodash'
import axios from 'axios'
import { checkFileSize } from './file'

let axiosInstance
let store
let envFolder
let apiUrl

const init = (context) => {
  axiosInstance = context.$axios
  store = context.store
  envFolder = context.$config.awsS3Folder
  apiUrl = context.$config.apiUrl
}

const uploadFolder = {
  temp: '/temp',
  // content item
  'curated-content': '/content-item/curated',
  'form-content': '/content-item/form',
  'graphic-content': '/content-item/graphic',
  'listing-content': '/content-item/listing',
  'listing-contact': '/content-item/listing/contact',
  // promotional content
  'promotional-content': '/promotional-content',
  // stream
  'stream-content-item': streamId => `/stream/${streamId}/content-item`,
  'stream-cover': streamId => `/stream/${streamId}/cover`,
  'stream-logo': streamId => `/stream/${streamId}/logo`,
  'stream-promotional-content': streamId => `/stream/${streamId}/promotional-content`,
  'stream-publish-schedule': streamId => `/stream/${streamId}/publish-schedule`,
  'stream-promote-item': streamId => `/stream/${streamId}/promote-item`,
  // user
  'user-avatar': '/user/avatar',
  'user-cover': '/user/cover',
  'user-branding': '/user/branding',
  // text-copy
  'text-copy': '/text-copy',
  'social-channel': '/social-channel'
}

const upload = async (urlSigned, file) => {
  try {
    const photoFile = file.blob || file
    const data = await axios.put(urlSigned, photoFile, {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read'
      }
    })
    return { dataUpload: data, err: null }
  } catch (error) {
    return { dataUpload: null, err: error }
  }
}

/**
 *
 * @param {*} fileName
 * @param {*} folderName type: String
 *
 * @enum in object uploadFolder
 * @returns
 */
const getSignedUrl = async (fileName, folderName) => {
  try {
    if (!folderName || typeof folderName !== 'string') {
      return { err: new Error('Folder incorrect'), data: null }
    }
    const payload = {
      folder: folderName,
      filename: fileName
    }
    const { data } = await axiosInstance.post('/upload-file', payload)
    return { err: null, data }
  } catch (err) {
    return { err, data: null }
  }
}

const getFilePreview = (file, key = 'thumb') => { // other value is `origin`
  if (file && typeof file === 'object') {
    return file.objectURL || file[key] || file.url
  }
  return file
}

const s3ProxyFile = path => typeof path === 'string' && path.indexOf('/') === 0 ? `${apiUrl}/s3-file?path=${path}` : path

const removeS3Proxy = path => (path || '').replace(`${apiUrl}/s3-file?path=`, '')

const removeAllS3Proxy = (str) => {
  if (str && typeof str === 'string') {
    const re = new RegExp(`${apiUrl}/s3-file\\?path=`, 'gm')
    return str.replace(re, '')
  }
  return str
}

const getFileUrl = (file) => {
  return file && file.url
}

const getUploadFileUrl = (file) => {
  return file && typeof file === 'object' ? (file.url || file.filePath || file.origin) : file
}

const getFile2Send = (obj, isMultiple = false) => {
  const imageObj = {}
  if (obj.type === 'library') {
    imageObj[isMultiple ? 'images' : 'imageUrl'] = isMultiple ? obj.libraryImages : get(obj, 'libraryImages[0].imageUrl')
    imageObj.canvaDesignLink = obj.canvaDesignLink
    imageObj.graphicContent = get(obj, 'graphicContent._id')
  } else if (obj.type === 'upload') {
    imageObj[isMultiple ? 'images' : 'imageUrl'] = isMultiple ? obj.uploadImages : obj.uploadImage
  } else {
    imageObj[isMultiple ? 'images' : 'imageUrl'] = isMultiple ? obj.images : obj.imageUrl
  }

  if (imageObj.imageUrl) {
    imageObj.canvaDesignId = imageObj.imageUrl.designId || imageObj.canvaDesignId
    imageObj.imageUrl = getUploadFileUrl(imageObj.imageUrl)
  }
  if (Array.isArray(imageObj.images) && imageObj.images.length) {
    imageObj.images = imageObj.images.map(image => ({
      ...image,
      imageUrl: getUploadFileUrl(image.imageUrl)
    }))
  }

  return imageObj
}

/**
 *
 * @param {*} file
 * @param {*} folderName
 * @param {*} id
 * @returns
 */
const uploadFile2S3 = async (file, key = 'temp', id = null) => {
  if (!file) {
    return file
  }
  if (!checkFileSize(file)) {
    throw new Error('File size exceeds 25MB')
  }
  const folderName = typeof uploadFolder[key] === 'function' ? uploadFolder[key](id) : uploadFolder[key]
  const { err, data } = await getSignedUrl(file.name, `${envFolder}${folderName}`)
  if (err) {
    throw err
  }
  const sId = Date.now()
  store.commit('setUploadStatus', { ...data, id: sId })
  await upload(data.signed, file)
  store.commit('unsetUploadStatus', { id: sId })
  return {
    sId,
    ...data,
    objectURL: URL.createObjectURL(file)
  }
}

export {
  init,
  getFilePreview,
  getFileUrl,
  getUploadFileUrl, // now use this function
  getFile2Send, // for case upload & use library
  uploadFile2S3,
  s3ProxyFile,
  removeS3Proxy,
  removeAllS3Proxy
}
