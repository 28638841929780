<template>
  <v-layout>
    <v-flex class="mr-3 flex-0-0 position-relative">
      <v-img
        :src="$helpers.getOrDefaultStreamCover(item.seoSetting && item.seoSetting.featureImage)"
        height="60"
        width="60"
        contain
        class="flex-0-0 rounded"
      />
    </v-flex>
    <v-flex>
      <p class="mb-1 text-subtitle-2 text-break">
        <v-chip v-if="badge" color="primary" small>
          {{ badge }}
        </v-chip>
        {{ item.name || '-' }}
      </p>
      <span class="text-caption font-italic secondary--text">
        {{ createdBy }} {{ item.createdAt | fromNow }}
      </span>
      <e-tags v-if="shouldShowTags" :items="item.tags" />
    </v-flex>
  </v-layout>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'DataTableStream',

  props: {
    item: {
      type: Object,
      required: true
    },
    badge: {
      type: String,
      default: ''
    }
  },

  computed: {
    createdBy() {
      if (this.item.createdBy) {
        return this.item.createdBy
      }
      if (this.item.owner !== this.$auth.user._id) {
        return 'EOwn’s Library'
      } else {
        return this.item.url ? 'Curated by me' : 'Created by me'
      }
    },
    shouldShowTags() {
      return get(this.item, 'tags', []).some(item => item.name)
    }
  }
}
</script>

<style>

</style>
