<template>
  <v-layout>
    <v-flex class="mr-3 flex-0-0 position-relative">
      <ESlider
        v-if="Array.isArray(item.images) && item.images.length"
        :ref="`e-view-photo-${item._id}`"
        :images="item.images"
        :width="imageWidth"
        class="flex-0-0"
      />
      <v-img
        v-else
        :src="$helpers.getFilePreview(item.imageUrl)"
        :height="imageWidth"
        :width="imageWidth"
        contain
        class="flex-0-0 rounded position-relative"
      />
      <div class="bg-gray-50 pa-1 rounded-lg position-absolute top-2 left-2 z-1">
        <img :src="`content-items/${item.category === 'weblink' ? 'weblink' : item.type}.svg` | assetImageUrl" class="w-10">
      </div>
    </v-flex>
    <v-flex>
      <p class="mb-1 text-subtitle-2 text-break">
        <v-chip v-if="badge" :color="badgeColor" small>
          {{ badge }}
        </v-chip>
        {{ item.title || '-' }}
      </p>
      <span class="text-caption font-italic secondary--text">
        {{ createdBy }} {{ item.createdAt | fromNow }}
      </span>
      <e-tags v-if="shouldShowTags" :items="item.tags" />
    </v-flex>
  </v-layout>
</template>

<script>
import { get } from 'lodash'
import ESlider from '@/plugins/components/e-slider.vue'

export default {
  name: 'DataTableContentItem',

  components: {
    ESlider
  },

  props: {
    item: {
      type: Object,
      required: true
    },
    showTag: {
      type: Boolean
    },
    imageWidth: {
      type: [Number, String],
      default: 80
    },
    badge: {
      type: String,
      default: ''
    },
    badgeColor: {
      type: String,
      default: 'primary'
    }
  },

  computed: {
    createdBy() {
      if (this.item.createdBy) {
        return this.item.createdBy
      }
      if (this.item.owner !== this.$auth.user._id) {
        return 'EOwn’s Library'
      } else {
        return this.item.url ? 'Curated by me' : 'Created by me'
      }
    },
    shouldShowTags() {
      return this.showTag && get(this.item, 'tags', []).some(item => item.name)
    }
  },

  methods: {
    openViewPhotoPopup(id) {
      const refViewPhoto = `e-view-photo-${id}`
      const interval = setInterval(() => {
        if (this.$refs[refViewPhoto]) {
          clearInterval(interval)
          this.$refs[refViewPhoto].viewAllPhotos()
        }
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
