import Vue from 'vue'
import VimeoPlayer from '@vimeo/player'
import YoutubePlayer from 'youtube-player'
import CKEditor from '@ckeditor/ckeditor5-vue2'

Vue.use(CKEditor)

Vue.use({
  install(Vue) {
    Vue.prototype.$vimeo = VimeoPlayer
  }
})

Vue.use({
  install(Vue) {
    Vue.prototype.$youtube = YoutubePlayer
  }
})
