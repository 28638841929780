import Vue from 'vue'

import VueCompositionAPI from '@vue/composition-api'
import EBack from './components/e-back.vue'
import EConfirmation from './components/e-confirmation.vue'
import EConfirm from './components/e-confirm.vue'
import EDataTable from './components/e-data-table.vue'
import EDialog from './components/e-dialog.vue'
import EInput from './components/e-input.vue'
import ESearch from './components/e-search.vue'
import ETags from './components/e-tags.vue'
import ETooltip from './components/e-tooltip.vue'
import EInfo from './components/e-info.vue'
import EDatetimePicker from './components/e-datetime-picker.vue'
import EReadMore from './components/e-read-more.vue'
import EOwnLogo from './components/eown-logo.vue'
import EUserMenu from './components/e-user-menu.vue'
import EPagination from './components/e-pagination.vue'
import EStatus from './components/e-status.vue'
import EModalInput from './components/e-modal-input.vue'

import EForm from './directives/e-type-form.js'
import IFrameActive from './directives/iframe-active'
import VLockOverlay from './directives/lock-overlay'

import ELoadingIcon from '@/components/common/loading-icon.vue'
import NotificationBell from '@/components/notification/bell.vue'

import DataTableContentItem from '@/components/data-table/content-item.vue'
import DataTableStream from '@/components/data-table/stream.vue'

import FilterPeriodDropdown from '@/components/filters/period-dropdown.vue'

import rules from '@/utils/rules'
import dataCy from '@/constants/data-cy'

const components = {
  EBack,
  EConfirmation,
  EConfirm,
  EDataTable,
  EDialog,
  EInput,
  ESearch,
  ETags,
  ETooltip,
  EDatetimePicker,
  EInfo,
  EReadMore,
  ELoadingIcon,
  NotificationBell,
  EOwnLogo,
  EUserMenu,
  EPagination,
  EStatus,
  EModalInput,

  // component use in data table
  DataTableContentItem,
  DataTableStream,

  FilterPeriodDropdown
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

Vue.use(VueCompositionAPI)

Vue.directive('e-form', EForm)
Vue.directive('iframe-active', IFrameActive)
Vue.directive('lock-overlay', VLockOverlay)

export default (context, inject) => {
  inject('formRules', rules)
  inject('dataCy', dataCy)
}
