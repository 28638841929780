<template>
  <v-layout class="flex-grow-0 mr-4">
    <v-menu
      offset-y
      nudge-bottom="8"
      :close-on-content-click="false"
    >
      <template #activator="{ on, attrs }">
        <v-layout
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            :content="importantNumber"
            :value="unreadCount > 0"
            color="red"
            overlap
            :dot="importantNumber === 0"
          >
            <v-icon
              v-bind="attrs"
              v-on="on"
            >
              mdi-bell
            </v-icon>
          </v-badge>
        </v-layout>
      </template>
      <v-card width="500">
        <List max-height="75vh" />
      </v-card>
    </v-menu>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import List from './list.vue'
import notificationRequest from '@/utils/notification-request'

export default {
  name: 'NotificationBell',

  components: {
    List
  },

  computed: {
    ...mapState({
      list: state => state.notification.list,
      unreadCount: state => state.notification.unreadCount
    }),

    unreadList() {
      return (this.list || []).filter(noti => !noti.isRead)
    },

    importantNumber() {
      const number = this.unreadList.filter(noti => noti.isImportant).length
      if (number > 5) { return '5+' }
      return number
    }
  },

  watch: {
    unreadCount(val, old) {
      if (!isNaN(val) && !isNaN(old) && Number(val) > Number(old)) {
        this.$store.commit('notification/updateList', {
          page: 1,
          list: null,
          isStopped: false
        })
        notificationRequest.index(this)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
