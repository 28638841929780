<template>
  <div v-if="cptTags && cptTags.length">
    <v-chip
      v-for="(tag, tIndex) in cptTags"
      :key="tIndex"
      :color="tagColor"
      :class="[`${color}--text`]"
      class="ma-1 px-2 py-1 h-auto font-weight-medium leading-1p333"
      small
    >
      {{ (tag && tag.name) ? tag.name : tag }}
      <v-icon
        v-if="removeable"
        small
        class="ml-1"
        @click="$emit('remove', tag)"
      >
        close
      </v-icon>
    </v-chip>
  </div>
  <div v-else-if="alwaysVisible" class="chip-fake" />
</template>

<script>
export default {
  name: 'ETags',

  props: {
    items: {
      type: Array,
      default: () => []
    },
    item: {
      type: [String, Object],
      default: () => ({})
    },
    color: {
      type: String,
      validator: v => ['primary', 'secondary', 'accent'].includes(v),
      default: 'primary'
    },
    removeable: Boolean,
    alwaysVisible: Boolean
  },

  computed: {
    cptTags() {
      if ((this.item && this.item.name) || typeof this.item === 'string') {
        return [this.item]
      }
      return this.items
    },
    tagColor() {
      switch (this.color) {
        case 'primary':
          return '#DCEEFF'
        case 'secondary':
          return '#D9D9D9'
        case 'accent':
          return '#F0F0F0'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chip-fake {
  height: 24px;
}
</style>
