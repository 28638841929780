const lastUnread = async (vueInstance) => {
  try {
    const { doc } = await vueInstance.$get('/notification/last-unread')
    return doc
  } catch (error) {

  }
}

const markAsRead = async (vueInstance, notificationId) => {
  try {
    const { doc } = await vueInstance.$post('/notification/mark-as-read', {
      notificationId
    })
    return doc
  } catch (error) {

  }
}

const index = async (vueInstance) => {
  try {
    const { page, isStopped } = vueInstance.$store.state.notification
    if (isStopped) { return }

    const result = await vueInstance.$axios.$get('/notification', {
      params: {
        page
      }
    })
    vueInstance.$store.commit('notification/updateList', {
      list: result.data,
      isStopped: result.isStopped
    })
    vueInstance.$store.commit('notification/setUnreadCount', result.unreadCount)
  } catch (error) {

  }
}

export default {
  index,
  lastUnread,
  markAsRead
}
