import { get } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  computed: {
    authUser() {
      return this.$auth.user
    },
    loggedIn() {
      return get(this.$auth, '$state.loggedIn')
    },
    connectAccountId() {
      return get(this.$auth, 'user.preferences.stripeConnect.connected') &&
        get(this.$auth, 'user.preferences.stripeConnect.accountId')
    },
    connectPayoutEnabled() {
      return get(this.$auth, 'user.preferences.stripeConnect.payoutEnabled', true)
    },
    ...mapGetters({
      connectAccount: 'user/connectAccount'
    }),
    currentPricings() {
      return (this.userPricing || []).filter(item => item.selected)
    },
    currentPricingKeys() {
      return this.currentPricings.map(item => item.key)
    },
    permissionRoles() {
      const roles = get(this.authUser, 'permissionRoles', []).filter(item => item.type !== 'free')
      if (!roles.length) {
        roles.push({ _id: 'free', name: 'Free' })
      }
      return roles
    },
    currentPricingName() {
      return this.permissionRoles.map(role => role.name).join(', ')
    },
    userSubscribeType() {
      return get(this.authUser, 'preferences.stripe.subscribeType', 'monthly')
    },
    pricingName() {
      const permissionRoles = get(this.authUser, 'permissionRoles', [])
        .filter(role => role.type !== 'free')
        .map(role => role.type)

      if (permissionRoles.length === 0) {
        return 'Free'
      }

      const names = permissionRoles.map(roleType => ({
        contentStreams: 'Business',
        listingMarketing: 'Property',
        digitalProducts: 'Product',
        emailMarketing: 'Email'
      }[roleType]))

      if (permissionRoles.length === get(this.userPricing, 'length', 0)) {
        return 'Everything'
      }

      return names.join(' & ') + ' promoter'
    }
  },

  watch: {
    '$route.path'() {
      this.$store.commit('user/setConnectAccount', null)
    }
  },

  data: () => ({
    userPricing: null,
    pricingConstant: {}
  }),

  methods: {
    async logout() {
      await this.$auth.logout()
    },

    async getConnectAccountInfo() {
      try {
        if (!this.userBalance) {
          const data = await this.$axios.$get('/users/connect-account/show')
          this.$store.commit('user/setConnectAccount', data)
        }
      } catch (error) {
        this.$helpers.notifyError(error)
      }
    },

    async createPayout() {
      try {
        await this.$axios.$post('/users/connect-account/payout')
        await this.getConnectAccountInfo()
        this.$helpers.notifySuccess('Payout successfully')
      } catch (error) {
        this.$helpers.notifyError(error)
      }
    },

    async loadUserPricing(isForce = false) {
      if (!this.loggedIn) { return }
      let promise = this.$store.getters['user/userPricingPromise']
      if (isForce || !promise) {
        promise = this.$axios.$get('/users/pricing')
        this.$store.commit('user/setUserPricingPromise', promise)
      }
      try {
        const { pricing, constant } = await promise
        this.userPricing = pricing.map(pricing => ({
          ...pricing,
          quantity: get(pricing, 'quantity') || 0
        }))
        this.pricingConstant = constant
      } catch (error) {
        this.$helpers.notifyError(error)
      }
    }
  }
}
