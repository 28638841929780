<template>
  <div>
    <v-dialog
      v-model="showPopup"
      max-width="1000"
      @input="hide"
    >
      <v-card class="px-7 py-10">
        <div class="position-absolute btn-close">
          <v-icon large @click="hide">
            close
          </v-icon>
        </div>

        <v-layout justify-center class="mb-5">
          <v-btn-toggle dense>
            <v-btn
              value="monthly"
              color="primary"
              :outlined="subscribeType !== 'monthly'"
              class="px-5"
              width="200"
              :disabled="periodDisabled"
              @click="subscribeType = 'monthly'"
            >
              <span :class="[{'white--text': subscribeType === 'monthly' && !periodDisabled}]">
                Monthly
              </span>
            </v-btn>

            <v-btn
              value="annual"
              color="primary"
              :outlined="subscribeType !== 'annual'"
              class="px-5"
              width="200"
              :disabled="periodDisabled"
              @click="subscribeType = 'annual'"
            >
              <span :class="[{'white--text': subscribeType === 'annual' && !periodDisabled}]">
                Yearly <a class="green--text">(Save 1 month)</a>
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-layout>

        <v-stepper v-model="step" flat>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row v-if="mainPlans && mainPlans.length" no-gutters>
                <v-col v-if="mainPlans[0]" :cols="12 / mainPlanSize">
                  <PricingPlanCheckbox
                    v-model="userPricing[mainPlans[0].index].selected"
                    type="contentStreams"
                    :pricing-data="mainPlans[0]"
                    :subscribe-type="subscribeType"
                    class="h-full"
                  />
                </v-col>
                <v-col v-if="mainPlanSize > 1" :cols="12 - (12 / mainPlanSize)">
                  <v-row no-gutters>
                    <v-col v-for="number in (mainPlanSize - 1)" :key="`mainPlans-${number}`" :cols="12 / ((mainPlanSize - 1))">
                      <PricingPlanCheckbox
                        v-model="userPricing[mainPlans[number].index].selected"
                        :type="mainPlans[number].key"
                        :pricing-data="mainPlans[number]"
                        :subscribe-type="subscribeType"
                        class="h-full"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-layout align-end class="mt-5 pa-2 pb-0">
                        <v-spacer />
                        <PricingTotal
                          :selected-plans="selectedPlans"
                          :subscribe-type="subscribeType"
                          :pricing-constant="pricingConstant"
                          :code="promotionCode"
                          class="flex-grow-0 mr-7"
                          @promotionCodeApplied="promotionCode = $event"
                        />
                        <v-btn
                          depressed
                          height="45"
                          color="primary"
                          dark
                          class="rounded-xl px-10 py-2"
                          :loading="$store.getters.isRequesting"
                          @click="nextStep()"
                        >
                          Next
                        </v-btn>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-layout
                v-else
                align-center
                justify-center
                class="my-15"
              >
                <ELoadingIcon color="#aaa" />
              </v-layout>
            </v-stepper-content>

            <v-stepper-content step="2">
              <EmailMarketing
                v-if="userPricing && userPricing[3]"
                v-model="userPricing[3]"
                :subscribe-type="subscribeType"
                @input="userPricing[3].selected = userPricing[3].quantity > 0"
              />

              <v-layout align-end class="mt-5 pa-2 pb-0">
                <v-btn
                  color="default"
                  depressed
                  height="45"
                  class="rounded-xl px-10 py-2"
                  @click="step -= 1"
                >
                  Back
                </v-btn>
                <v-spacer />
                <PricingTotal
                  :selected-plans="selectedPlans"
                  :subscribe-type="subscribeType"
                  :pricing-constant="pricingConstant"
                  :code="promotionCode"
                  class="flex-grow-0 mr-7"
                  @promotionCodeApplied="promotionCode = $event"
                />
                <v-btn
                  depressed
                  height="45"
                  color="primary"
                  dark
                  class="rounded-xl px-10 py-2"
                  :loading="$store.getters.isRequesting"
                  @click="selectPlan()"
                >
                  Update<br>Subscription
                </v-btn>
              </v-layout>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get, intersection } from 'lodash'
import MixinAuth from '../../../mixins/auth'
import PricingPlanCheckbox from '../../billing/pricing-plan-checkbox.vue'
import EmailMarketing from '../../billing/email-marketing.vue'
import PricingTotal from '../../billing/pricing-total.vue'

export default {
  name: 'PopupPricingPlan',

  components: {
    PricingPlanCheckbox,
    EmailMarketing,
    PricingTotal
  },

  mixins: [MixinAuth],

  data: () => ({
    prices: [],
    plan: '',
    subscribeType: 'monthly',
    promotionCode: null,
    showPopup: false,
    step: 1
  }),

  computed: {
    selectedPlans() {
      return (this.userPricing || []).filter(item => item.selected)
    },
    selectedMainPlans() {
      return (this.mainPlans || []).filter(item => item.selected)
    },
    mainPlanSize() {
      return (this.mainPlans || []).length
    },
    mainPlans() {
      return (this.userPricing || []).map((item, index) => ({ ...item, index })).filter(pricing => get(this.pricingConstant, 'mainRoles', []).includes(get(pricing, 'key')))
    },
    periodDisabled() {
      return this.step === 2 && this.selectedMainPlans.length > 0
    }
  },

  created() {
    this.$nuxt.$off('pricing/openPopup')
    this.$nuxt.$off('pricing/selectPlan')
    this.$nuxt.$on('pricing/openPopup', async () => {
      this.setSubscribeType()
      this.userPricing = null
      this.openPopup()
      await this.loadUserPricing(true)
    })
    this.$nuxt.$on('pricing/selectPlan', async (pricingKeys, subscribeType) => {
      if (subscribeType) {
        this.subscribeType = subscribeType
      } else {
        this.setSubscribeType()
      }
      this.userPricing = null
      this.openPopup()
      await this.loadUserPricing(true)
      this.triggerSelectPlan(pricingKeys)
    })
  },

  mounted() {
    this.setSubscribeType()
  },

  methods: {
    setSubscribeType() {
      this.subscribeType = this.userSubscribeType
    },
    hide() {
      this.showPopup = false
      this.promotionCode = null
    },
    openPopup() {
      this.showPopup = true
      this.step = 1
    },
    triggerSelectPlan(pricingKeys = []) {
      if (intersection(pricingKeys, get(this.pricingConstant, 'mainRoles', [])).length === 0) {
        this.step = 2
      }
      console.log('pricingKeys', pricingKeys)
      this.userPricing = this.userPricing.map(pricing => ({
        ...pricing,
        selected: pricingKeys.includes(pricing.key)
      }))
      console.log('this.userPricing', this.userPricing)
      this.selectPlan()
    },
    async selectPlan() {
      try {
        const selectedPricing = this.userPricing.filter(item => item.selected)
        const pricingKeys = selectedPricing.map(item => ({ id: item.key, quantity: get(item, 'quantity') || 1 }))
        await this.$store.commit('user/addPlanHandlerAction', {
          name: 'handle',
          params: [{ pricingKeys, subscribeType: this.subscribeType, promotionCode: this.promotionCode }]
        })
        this.$helpers.gaTracking('Upgrade', 'upgrade', pricingKeys, { event_plan: pricingKeys })
      } catch (error) {
        this.$notify.error({
          title: this.isLoginPopup ? 'Login' : 'Sign Up',
          message: this.$helpers.apiErrorMessage(error)
        })
      }
    },
    onQuantityChange(quantity, number) {
      this.userPricing[number].quantity = Number(quantity)
    },
    nextStep() {
      if (!this.stepIsValid()) {
        return
      }

      this.step += 1
    },
    stepIsValid() {
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-close {
  top: 16px;
  right: 28px;
  z-index: 100;
}
</style>
