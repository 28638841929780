let axiosInstance

const init = (context) => {
  axiosInstance = context.$axios
}

const getPublishedUrl = async (streamId, channel) => {
  const url = await axiosInstance.$get(`/streams/${streamId}/published-url`)
  return {
    status: url.status,
    origin: url.origin,
    pathname: url.pathname,
    search: `?channel=${channel || ''}`,
    href: `${url.href}?channel=${channel || ''}`
  }
}

const getValidatePublish = async (streamId, params) => {
  const validate = await axiosInstance.$get(`/streams/${streamId}/validate-publish`, { params })
  return validate
}

export {
  init,
  getPublishedUrl,
  getValidatePublish
}
