/* eslint-disable prefer-promise-reject-errors */
import { get, toLower, isObject, keys, isFunction } from 'lodash'
import { getFilePreview } from './upload-s3'

const copyToClipboard = (str, url, vueNotify, message = 'Copied to clipboard') => {
  const el = document.createElement('textarea')
  el.addEventListener('focusin', e => e.stopPropagation())
  el.value = [stripHtml(str), stripHtml(url)].filter(Boolean).join('\n')
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (vueNotify) {
    vueNotify.success({ message })
  }
}

const download = async (url, name) => {
  function toDataURL(url) {
    return fetch(url).then((response) => {
      return response.blob()
    }).then((blob) => {
      return URL.createObjectURL(blob)
    })
  }
  const a = document.createElement('a')
  a.href = await toDataURL(url)
  a.download = name || 'Downloads.png'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function getCookie(cname) {
  const name = cname + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const isPhoto = (fileName) => {
  return fileName && fileName.match(/(\.|\/)(jpeg|jpg|png|gif)$/gmi)
}

const openPopupWindow = (url, config = {}, broadcastConfig = {}) => {
  const windowArea = {
    width: config.width || Math.floor(window.outerWidth * 0.3),
    height: config.height || Math.floor(window.outerHeight * 0.9)
  }

  if (windowArea.width < 400) { windowArea.width = 400 }
  if (windowArea.height < 650) { windowArea.height = 650 }
  windowArea.left = Math.floor(window.screenX + ((window.outerWidth - windowArea.width) / 2))
  windowArea.top = Math.floor(window.screenY + ((window.outerHeight - windowArea.height) / 8))

  const sep = (url.includes('?')) ? '&' : '?'
  const nUrl = `${url}${sep}`
  const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
    width=${windowArea.width},height=${windowArea.height},
    left=${windowArea.left},top=${windowArea.top}`

  const subwindow = window.open(nUrl, `subWindow${Date.now()}`, windowOpts)

  if (broadcastConfig && isObject(broadcastConfig)) {
    keys(broadcastConfig).forEach((key) => {
      if (!isFunction(broadcastConfig[key])) {
        return
      }

      if (!window._popupWindowBroadcast) {
        window._popupWindowBroadcast = {}
      }

      if (window._popupWindowBroadcast[key]) {
        window._popupWindowBroadcast[key].close()
      }

      window._popupWindowBroadcast[key] = new BroadcastChannel(key)
      window._popupWindowBroadcast[key].addEventListener('message', (e) => {
        broadcastConfig[key](e, subwindow)
      })
    })
  }

  return subwindow
}

/* global window */
const popup = (url, isFacebook = false, config = {}) => {
  const authWindow = openPopupWindow(url, config)
  // Listen to message from child window
  const authPromise = new Promise((resolve, reject) => {
    if (isFacebook) {
      const channel = new BroadcastChannel('eown-facebook')
      channel.addEventListener('message', (e) => {
        if (e.origin !== window.location.origin) {
          authWindow.close()
          reject('Not allowed')
        }
        if (e.data.auth) {
          // resolve(JSON.parse(e.data.auth))
          authWindow.close()
          resolve(e.data.auth)
        } else {
          authWindow.close()
          reject('Unauthorised')
        }
      })
    } else {
      // Create IE + others compatible event handler
      const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
      const eventer = window[eventMethod]
      const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
      eventer(messageEvent, (e) => {
        // console.log('e.origin', e.origin)
        // console.log('window.location.origin', e.origin)
        // console.log('window.location.origin', window.location.origin)
        // if (e.origin !== window.SITE_DOMAIN) {
        if (e.origin !== window.location.origin) {
          authWindow.close()
          reject('Not allowed')
        }
        // console.log('authPromise', e)
        // console.log('e.data.auth', e.data.auth)
        if (e.data.auth) {
          // resolve(JSON.parse(e.data.auth))
          resolve(e.data.auth)
          authWindow.close()
        } else {
          authWindow.close()
          reject('Unauthorised')
        }
      }, false)
    }
  })

  return authPromise
}

const sortCallBack = (valuePath, asc = true, type) => {
  const weight = asc ? 1 : -1
  return function (item1, item2) {
    let compareValue1 = valuePath ? get(item1, valuePath) : item1
    let compareValue2 = valuePath ? get(item2, valuePath) : item2

    if (!compareValue1 && !compareValue2) {
      return 0
    }
    if (!compareValue1 && compareValue2) {
      return -1 * weight
    }
    if (compareValue1 && !compareValue2) {
      return 1 * weight
    }

    switch (toLower(type)) {
      case 'number':
        compareValue1 = Number(compareValue1) || 0
        compareValue2 = Number(compareValue2) || 0
        break
      case 'date':
        compareValue1 = new Date(compareValue1)
        compareValue2 = new Date(compareValue2)
        break
      case 'boolean':
        compareValue1 = Number(compareValue1 || 0)
        compareValue2 = Number(compareValue2 || 0)
        break
      default:
        compareValue1 = compareValue1 || ''
        compareValue2 = compareValue2 || ''
        break
    }
    return (compareValue1 > compareValue2 ? 1 : compareValue1 === compareValue2 ? 0 : -1) * weight
  }
}

const stripHtml = (html) => {
  if (html) {
    const tmp = document.createElement('div')
    tmp.innerHTML = html
    // eslint-disable-next-line unicorn/prefer-text-content
    return tmp.textContent || tmp.innerText || ''
  }
  return html
}

const popupCanva = (url) => {
  // console.log('popupCanva', url)
  // const authWindow = window.open(url, 'blank')
  // const buttonSelect = authWindow.document.querySelector('.zKTE_w.zQlusQ')
  // window.authWindow = authWindow
  // console.log('buttonSelect', buttonSelect)
  // console.log('authWindow', authWindow)
  // console.log('authWindow', authWindow.document.body.innerHTML)
  // const windowArea = {
  //   width: Math.floor(window.outerWidth * 0.3),
  //   height: Math.floor(window.outerHeight * 0.9)
  // }

  // if (windowArea.width < 300) { windowArea.width = 300 }
  // if (windowArea.height < 630) { windowArea.height = 630 }
  // windowArea.left = Math.floor(window.screenX + ((window.outerWidth - windowArea.width) / 2))
  // windowArea.top = Math.floor(window.screenY + ((window.outerHeight - windowArea.height) / 8))
  // const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
  //   width=${windowArea.width},height=${windowArea.height},
  //   left=${windowArea.left},top=${windowArea.top}`

  // Listen to message from child window
  // const authPromise = new Promise((resolve, reject) => {
  //   if (isFacebook) {
  //     const channel = new BroadcastChannel('eown-facebook')
  //     channel.addEventListener('message', (e) => {
  //       if (e.origin !== window.location.origin) {
  //         authWindow.close()
  //         reject('Not allowed')
  //       }
  //       if (e.data.auth) {
  //         // resolve(JSON.parse(e.data.auth))
  //         authWindow.close()
  //         resolve(e.data.auth)
  //       } else {
  //         authWindow.close()
  //         reject('Unauthorised')
  //       }
  //     })
  //   } else {
  //     // Create IE + others compatible event handler
  //     const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
  //     const eventer = window[eventMethod]
  //     const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
  //     eventer(messageEvent, (e) => {
  //       // console.log('e.origin', e.origin)
  //       console.log('window.location.origin', e.origin)
  //       console.log('window.location.origin', window.location.origin)
  //       // if (e.origin !== window.SITE_DOMAIN) {
  //       if (e.origin !== window.location.origin) {
  //         authWindow.close()
  //         reject('Not allowed')
  //       }
  //       console.log('authPromise', e)
  //       console.log('e.data.auth', e.data.auth)
  //       if (e.data.auth) {
  //         // resolve(JSON.parse(e.data.auth))
  //         resolve(e.data.auth)
  //         authWindow.close()
  //       } else {
  //         authWindow.close()
  //         reject('Unauthorised')
  //       }
  //     }, false)
  //   }
  // })

  return true
}

/**
 * Func help generate uuidv4
 */
const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const getContentItemImage = (contentItem, key = 'thumb', defaultImg = true) => {
  let imageUrl = ''
  if (Array.isArray(contentItem.images) && contentItem.images.length) {
    const image = contentItem.images.find(img => img.isThumbnail) || contentItem.images[0]
    imageUrl = getFilePreview(image.imageUrl ? image.imageUrl : image, key)
  } else {
    imageUrl = contentItem.objectURL || contentItem.previewUrl || getFilePreview(contentItem.imageUrl, key)
  }
  if (defaultImg) {
    return imageUrl || require('~/assets/images/content/feature-image.png')
  }
  return imageUrl
}

// const emailConfig = {
//   mailto: 'help@eown.io',
//   subject: '[User support] Email verification',
//   body: [
//     'Hi EOwn’s Customer Success Team,',
//     'I need support for verifying my email.',
//     'Regards,'
//   ].join('%20%0D%0A')
// }
// const mailtoVerificationHelp = `mailto:${emailConfig.mailto}?subject=${emailConfig.subject}&body=${emailConfig.body}`

const mailtoVerificationHelp = 'https://eown.io/contact-us/'

const extractVideoUrl = (videoInputStr) => {
  let extractUrl = {}
  if (videoInputStr) {
    const url = videoInputStr.includes('iframe') ? videoInputStr.match(/\bhttps?:\/\/\S+/gi)[0].substring(0, videoInputStr.match(/\bhttps?:\/\/\S+/gi)[0].length - 1) : videoInputStr

    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const matches = url.match(/\bhttps?:\/\/\S+/gi)
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=)([^#&?]*).*/
      const match = matches && matches[0].match(regExp)
      const idRegExp = (match && match[2].length === 11) ? match[2] : ''
      const idYou = idRegExp.length > 11 ? idRegExp.substring(0, idRegExp.length - 1) : idRegExp
      extractUrl = {
        embedUrl: `https://www.youtube.com/embed/${idYou}`,
        originUrl: `https://youtu.be/${idYou}`
      }
    }

    if (url.includes('vimeo.com')) {
      const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i
      const parsed = url.match(vimeoRegex)
      extractUrl = {
        embedUrl: `https://player.vimeo.com/video/${parsed[1]}`,
        originUrl: `https://vimeo.com/${parsed[1]}`
      }
    }
  }
  return extractUrl
}

const formatCurrency = (number = 0, currency = 'USD') => Number(number).toLocaleString('en-US', {
  style: 'currency',
  currency
}).replace(/\.00$/, '')

function convertAndHyphenate(input) {
  // Use a regular expression to find and replace the characters
  return input.replace(/([a-z])([0-9])/g, '$1-$2').replace(/([0-9])([a-z])/g, '$1-$2').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

const toHyphenated = (input) => {
  return input.toLowerCase().replace(/ /g, '-')
}

export {
  copyToClipboard,
  download,
  getCookie,
  isPhoto,
  popup,
  openPopupWindow,
  sortCallBack,
  stripHtml,
  popupCanva,
  uuidv4,
  getContentItemImage,
  mailtoVerificationHelp,
  extractVideoUrl,
  formatCurrency,
  convertAndHyphenate,
  toHyphenated
}
