let axiosInstance

const init = (context) => {
  axiosInstance = context.$axios
}

const getMicrositeDetails = async (params) => {
  const { data } = await axiosInstance.$get('/content-items/details', { params })
  return data
}

const getPublishedUrl = async (contentItemId) => {
  const url = await axiosInstance.$get(`/content-items/${contentItemId}/published-url`)
  return url
}

export {
  init,
  getMicrositeDetails,
  getPublishedUrl
}
