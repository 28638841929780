import { get } from 'lodash'
import NotificationRequest from '@/utils/notification-request'

export default async ({ $axios, store, $auth }) => {
  if (!get($auth, 'loggedIn')) {
    store.commit('notification/updateList', {
      page: 1,
      list: null,
      isStopped: false
    })
  }
  if (get($auth, 'loggedIn') && !get(store, 'state.notification.list')) {
    await NotificationRequest.index({ $axios, $store: store })
  }
}
