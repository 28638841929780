import { init } from '../utils/upload-s3'

import { init as streamServicesInit } from '../services/streams/index'
import { init as contentItemServicesInit } from '../services/content-items/index'

export default (context, inject) => {
  init(context)
  streamServicesInit(context)
  contentItemServicesInit(context)
}
